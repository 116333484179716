import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';

import { AggType, useGetConsumedDataQuery } from 'api/data';
import ConsumedDataView from 'components/DataView/Consumed';
import Navbar from 'components/GenericNavbar';
import RealtimeJoyrideTour from 'components/JoyrideTour/realtime';
import MapView from 'components/MapView';
import { getMapData } from 'components/MapView/utils';
import OptionsToolbar from 'components/OptionsToolbar';
import SettingsModal from 'components/SettingsModal';
import { MapLevel } from 'constants/enums';
import dayjs from 'dayjs';
import { currentAppState, currentMapHistory, FeatureGate, hasGateEnabled, hasReceivedAuth } from 'modules/app/selectors';
import { editMapSelection, IMapState, updateAppState } from 'modules/app/slice';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { last } from 'utils/utils';


const mapLevelToAggType: Record<MapLevel, AggType> = {
  [MapLevel.MISO]: "Footprint",
  [MapLevel.Subregions]: "Subregion",
  [MapLevel.MISO_States]: "State",
  [MapLevel.MISO_Counties]: "County",
  [MapLevel.LRZs]: "LRZ",
  [MapLevel.LBAs]: "LBA",

  // Should be impossible to select these
  [MapLevel.BAs]: "Footprint",
  [MapLevel.LARGE_BAs]: "Footprint",
  [MapLevel.ISOs]: "Footprint",
  [MapLevel.States]: "State",
}

const ConsumedPage = () => {
  const navigate = useNavigate();
  const receivedAuth = useAppSelector(hasReceivedAuth);
  const hasConsumed = useAppSelector(s => hasGateEnabled(s, FeatureGate.MARCH_2025_LAUNCH));
  if (receivedAuth && !hasConsumed) {
    navigate("/", {replace: true});
  }

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(editMapSelection({editMode: "overwrite", mapLevel: MapLevel.MISO, mapSelection: new Set(["MISO"])}));
    dispatch(updateAppState({
      queryStartDate: dayjs().startOf('day'),
      queryEndDate: dayjs().endOf('day'),
    }));
  }, [dispatch]);

  const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);
  const appState = useAppSelector(currentAppState);
  const start = appState.queryStartDate;
  const end = appState.queryEndDate;
  const dateRangeTooBig = start.diff(end, 'day') > 1;

  let aggIds = mapState.mapLevel === MapLevel.MISO ? ["true"] : Array.from(mapState.mapSelection);
  if (mapState.mapLevel === MapLevel.MISO_Counties) {
    // The carbonflube API only accepts the "county (state)" format for counties that exist
    // in multiple states in MISO. This should be fixed to accept the state for all counties.
    const mapDataToShow = getMapData(appState, mapState, true);
    const mapRegionCodes = mapDataToShow.features.map((f: any) => f.properties.apiRegionCode);
    const duplicates = mapRegionCodes.filter(
      (x: string) => mapRegionCodes.filter(
        (y: string) => y.startsWith(x.replace(/ \(.*\)/, ''))
      ).length > 1
    );
    aggIds = aggIds.map(x => duplicates.includes(x) ? x : x.replace(/ \(.*\)/, ''));
  }
  const {
    data,
    isLoading,
    isFetching,
  } = useGetConsumedDataQuery({
    start: start.tz('US/Central', true).startOf('day').toDate().toISOString(),
    end: end.tz('US/Central', true).endOf('day').toDate().toISOString(),
    agg_type: mapLevelToAggType[mapState.mapLevel],
    agg_ids: aggIds.join(",")
  }, {skip: !hasConsumed || (!mapState || mapState.mapSelection.size === 0 || dateRangeTooBig)});

  return <div className="App">
    <Container maxWidth={false} disableGutters={true}>
      <Navbar />

      <RealtimeJoyrideTour />

      <SettingsModal
        open={settingsMenuOpen}
        setOpen={setSettingsMenuOpen}
      />

      <OptionsToolbar
        toggleSettingsMenu={() => setSettingsMenuOpen(!settingsMenuOpen)}
        datePickerType='start-end'
        minDate={dayjs("2024-06-01")}
        leftMostSelection='regionSource'
        showResolution={false}
      />

      <Grid container>
        <Grid item xs={12} lg={7} order={{ xs: 2, lg: 1 }}>
          <ConsumedDataView data={data} isLoading={isLoading || isFetching} noData={!mapState || mapState.mapSelection.size === 0}/>
        </Grid>
        <Grid item xs={12} lg={5} order={{ xs: 1, lg: 2 }}>
          <MapView
            allowedMapLevels={[MapLevel.MISO, MapLevel.Subregions, MapLevel.LRZs, MapLevel.LBAs, MapLevel.MISO_States, MapLevel.MISO_Counties]}
            showPlants={false}
            showRegionSelect={mapState.mapLevel !== MapLevel.MISO}
            showZoomButton={false}
          />
        </Grid>
      </Grid>
    </Container>
  </div>
}

export default ConsumedPage;