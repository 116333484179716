let ResourcesConstants;

// @ts-ignore
if (ENVIRONMENT === "production") {
  ResourcesConstants = {
    API_URL: "https://miso.api.singularity.energy",
    HEAP_APP_ID: '3809040412',
    DEBUG: false
  };
  // @ts-ignore
} else if (ENVIRONMENT === "prod_v2") {
  ResourcesConstants = {
    API_URL: "https://miso-v2.api.singularity.energy",
    HEAP_APP_ID: '3809040412',
    DEBUG: false
  };
  // @ts-ignore
} else if (ENVIRONMENT === "staging" || ENVIRONMENT === "qa") {
  ResourcesConstants = {
    // @ts-ignore
    API_URL: API_BASE_URL || "https://miso.api.qa-singularity.energy",
    HEAP_APP_ID: '3462342852',
    DEBUG: true
  };
} else {
  ResourcesConstants = {
    // @ts-ignore
    API_URL: API_BASE_URL || "http://lcl.qa-singularity.energy:5010",
    // API_URL: API_BASE_URL || "https://miso.api.qa-singularity.energy",
    // API_URL: API_BASE_URL || "http://localhost:5011/api",

    HEAP_APP_ID: '3462342852',
    DEBUG: true
  };
}

export const FONT_FAMILY = 'Helvetica Neue';
export const HEAP_APP_ID = ResourcesConstants.HEAP_APP_ID;

export const TOUR_BUTTON_ID = 'se--start-tour-button';
export const DOWNLOAD_LINK_ID = 'data-download-link';
export const DOWNLOAD_BUTTON_ID = 'data-download-button';

export const DEBUG = ResourcesConstants.DEBUG;
export const API_URL = ResourcesConstants.API_URL;

export const MAPBOXGL_ACCESS_TOKEN = 'pk.eyJ1Ijoic2luZ3VsYXJpdHktZW5lcmd5IiwiYSI6ImNsM2FmMjRybDAwdzUzZ3BrNnZwaTYwZjIifQ.WOLczvbtOOu7y1V8g08AGA';

export const EARLIEST_DATA_YEAR = 2005;
export const LATEST_DATA_YEAR = 2022;
export const EARLIEST_HOURLY_OR_DAILY_DATA_YEAR = 2019;
export const LATEST_HOURLY_OR_DAILY_DATA_YEAR = 2022;
export const LATEST_PROJECTIONS_YEAR = 2041;
export const MAX_DAYS_OF_HOURLY_DATA = 14;
export const MAX_MONTHS_OF_DAILY_DATA = 4;

export const DATA_DOWNLOAD_README_URL = 'https://singularity-public-assets.s3.amazonaws.com/miso/README.md';
export const DASHBOARD_DOCS_URL = 'https://docs.singularity.energy/docs/open-grid-emissions';

// https://docs.npmjs.com/about-semantic-versioning
export const DASHBOARD_VERSION = '1.1.0'

// Update this whenever we add new features and want to show the user a walkthrough again.
// It should probably get bumped with each major release of the DASHBOARD_VERSION above.
export const CURRENT_TOUR_VERSION = '1.1.0';

export const KG_PER_LB = 0.453592;
export const MISO_STATES = [
  "North Dakota",
  "South Dakota",
  "Minnesota",
  "Iowa",
  "Wisconsin",
  "Michigan",
  "Indiana",
  "Illinois",
  "Missouri",
  "Kentucky",
  "Arkansas",
  "Mississippi",
  "Louisiana",
]