import CloudOffRounded from '@mui/icons-material/CloudOffRounded';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import * as Constants from 'constants/constants';
import dayjs from 'dayjs';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { flatten, snakeCase } from 'lodash';
import React, { useEffect } from 'react';

import { IConsumedDatum, IGetConsumedDataResponse } from 'api/data';
import { KG_PER_LB } from 'constants/constants';
import { Units } from 'constants/enums';
import { currentAppState, currentMapHistory } from 'modules/app/selectors';
import { IMapState } from 'modules/app/slice';
import { useAppSelector } from 'modules/store';
import { fuelCategoryPalette } from 'utils/ColorPalette';
import { arraySum, getBrowserWidth, last } from 'utils/utils';

import { HelpPopup } from 'components/HelpPopup';
import './style.css';
import { createDownloadFunction } from './utils';


const makeChartOptions = (
  series: {data: {x: number, y: number}[], zIndex?: number, name: string, color?: string, type?: string, yAxis?: number}[],
  chartType: string,
  title: string = '',
  yAxisTitle: string = '',
  extraPlotOptions: any = {},
  secondaryYAxisTitle: string = '',
  yAxisFormatter?: ({value}: {value: number}) => string
) => {
  const startOfToday = new Date();
  startOfToday.setUTCHours(0, 0, 0, 0);
  const endOfToday = dayjs().tz('US/Central', true).endOf('day').toDate();
  const minX = Math.min(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const numAfterTodayStart = flatten(series.map(d => d.data.filter(d1 => d1.x > startOfToday.valueOf()))).length;
  const numTotalData = flatten(series.map(d => d.data)).length;
  // not the best way to determine this, but it works
  const isTodayData = numAfterTodayStart > (numTotalData/2);
  const maxX = Math.max(...flatten(series.map(({data}) => data.map(({x}) => x))));
  const yAxes: any = [{title: {text: yAxisTitle, useHTML: true}, softMin: 0, labels: {formatter: yAxisFormatter}}];
  if (secondaryYAxisTitle) {
    yAxes.push({
      title: {text: secondaryYAxisTitle, useHTML: true},
      opposite: true,
      softMin: 0,
    });
  }
  return {
    chart: {
      type: chartType,
    },
    time: {
      // Positive offset means WEST of GMT, negative means EAST.
      timezoneOffset: dayjs().tz('US/Central', true).utcOffset() * -1,
    },
    title: {
      text: title,
    },
    series,
    plotOptions: {
      ...extraPlotOptions,
    },
    xAxis: {
      title: {
        text: ''
      },
      type: 'datetime',
      minPadding: 0.05,
      maxPadding: 0.05,
      max: isTodayData ? endOfToday.valueOf() : maxX,
      min: minX,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        y: 25,
      },
      tickInterval: 3600 * 1000,
      plotLines: [{
        color: 'var(--color-blue-2)',
        label: {
          text: 'Now',
        },
        dashStyle: 'ShortDash',
        value: Date.now()
      }],
    },
    credits: {enabled: false},
    yAxis: yAxes,
    exporting: {
      enabled: true,
      scale: 4,
      buttons: {
        contextButton: {
          symbol: "menuball",
          menuItems: ["viewFullscreen", "separator", "downloadCSV", "downloadPNG", "downloadJPEG", "downloadSVG"],
        },
      },
      menuItemDefinitions: {
        downloadCSV: {
          onclick: function () {
            document.getElementById(Constants.DOWNLOAD_BUTTON_ID).click();
          },
          text: 'Download CSV'
        }
      },
      chartOptions: {
        navigator: {
          enabled: false
        },
        scrollbar: {
          enabled: false
        },
        rangeSelector: {
          enabled: false
        },
        chart: {
          style: {
            fontFamily: Constants.FONT_FAMILY,
          },
        }
      },
      fallbackToExportServer: false
    },
  }
};


const roundDecimals = (number: number): number => {
  const digitsBeforeDecimal = number > 1 ? Math.floor(Math.log10(number)) + 1 : 0;
  return parseFloat(number.toPrecision(digitsBeforeDecimal + 2));
}


const makeFuelMixChartOptions = (data: IGetConsumedDataResponse) => {
  const seriesData: Record<string, {x: number, y: number}[]> = {};

  data.data.forEach((datum: IConsumedDatum) => {
    Object.entries(datum).forEach(([key, value]) => {
      const matches = key.match(/(.*)ConsumedMwh/);
      const fuel = matches && snakeCase(matches[1]);
      if (fuel && fuel in fuelCategoryPalette) {
        if (!seriesData[fuel]) {
          seriesData[fuel] = [];
        }
        seriesData[fuel].push({y: roundDecimals(value), x: new Date(datum.timestamp).valueOf()});
      }
    });
  });

  const series = Object
    .entries(seriesData)
    .map(([fuelName, fuelData]) => ({name: fuelName.replaceAll('_', ' '), color: fuelCategoryPalette[fuelName], data: fuelData, tooltip: {valueSuffix: 'MW'}}))
    .sort((d1, d2) => {
      if (d1.name === "storage") {
        return 1;
      } else if (d2.name === "storage") {
        return -1;
      } else {
        return arraySum(d1.data.map(({y}) => y)) - arraySum(d2.data.map(({y}) => y));
      }
    });

  return makeChartOptions(series, 'area', '', 'Consumption', {
    series: {
      stacking: 'normal',
      dataGrouping: {
        enabled: false
      },
    },
    area: {marker: {enabled: false}},
  }, undefined, ({value}: {value: number}) => {
    if (value > 1000) {
      return `${value / 1000} GW`;
    } else if (value !== 0) {
      return `${value} MW`;
    } else {
      return '0';
    }
  });
}

const getRate = (datum: IConsumedDatum, isImperialUnits: boolean) => {
  const consumedLbs = datum.co2EConsumedLbs;
  const consumedMwh = datum.consumedMwh;
  if (consumedMwh === 0) {
    return 0;
  }
  const factor = isImperialUnits ? 1 : KG_PER_LB;
  return (consumedLbs / consumedMwh) * factor;
};

const makeEmissionsChartOptions = (data: IGetConsumedDataResponse, isImperialUnits: boolean) => {
  const consumedData = data.data
    .map(datum => ({y: Math.round(getRate(datum, isImperialUnits)), x: new Date(datum.timestamp).valueOf()}))
    .filter(({y}) => y !== 0);

  return makeChartOptions(
    [
      {name: 'Consumed Carbon Intensity', data: consumedData, color: 'red', type: 'spline', zIndex: 1},
      {
        data: data.data.map(datum => ({
          y: roundDecimals(datum.co2EConsumedLbs / (isImperialUnits ? 2000 : 2204.62)),
          x: new Date(datum.timestamp).valueOf()
        })),
        name: 'Total Consumed Emissions', yAxis: 1
      },
    ],
    'areaspline',
    '',
    `intensity - CO<sub>2</sub> ${isImperialUnits ? 'lbs' : 'kg'}/MWh`,
    {areaspline: {marker: {enabled: false}}, spline: {marker: {enabled: false}}},
    `emissions - ${isImperialUnits ? '' : 'metric '} tons CO<sub>2</sub>`,
  );
}

const dataIsEmpty = (data: IGetConsumedDataResponse) => {
  return data.data.length === 0;
}


const NoDataWarning = () => {
  return (
    <div className="realtime-empty-state--container">
      <div className="realtime-empty-state-content--container">
        <div className="realtime-empty-state-icon--container"><CloudOffRounded className="realtime-empty-state--icon" /></div>
        <div className="realtime-empty-state-explanation--container">
          <h3 className="realtime-empty-state-explanation--header">We don't appear to have data!</h3>
          <p className="realtime-empty-state-explanation--text">Apologies, but we don't appear to have data for the selected days or regions. Please try a different selection.</p>
        </div>
      </div>
    </div>
  )
};


const ConsumedDataView = ({
  data,
  isLoading,
  noData,
}: {data: IGetConsumedDataResponse, isLoading: boolean, noData: boolean}) => {
  const appState = useAppSelector(currentAppState);
  const mapHistory = useAppSelector(currentMapHistory);
  const mapState = last<IMapState | undefined>(mapHistory);

  const rows: (string | number)[][] = []
  if (!isLoading && data && data.data.length > 0) {
    const fuelKeys = Object.keys(data.data[0]).filter(x => x.toLowerCase().includes("consumedmwh"));
    rows.push(["timestamp", "consumed_co2e_intensity_lbs_per_mwh", "co2e_consumed_lbs", ...fuelKeys.map(snakeCase)]);
    data.data.forEach(datum => {
      const row = [datum.timestamp, getRate(datum, true).toFixed(2), datum.co2EConsumedLbs.toFixed(2)];
      fuelKeys.forEach(key => row.push((datum[key as keyof IConsumedDatum] as number).toFixed(2)));
      rows.push(row);
    });
  }

  useEffect(() => {
    const button = document.getElementById(Constants.DOWNLOAD_BUTTON_ID);
    if (button) {
      button.onclick = createDownloadFunction(appState, mapState, rows);
    }
  });

  const dataPanelStyle = {
    overflow: 'visible'
  };
  if (getBrowserWidth() >= 1200) {
    dataPanelStyle['overflow'] = 'scroll';
  }

  if (noData) {
    return <div className="realtime-data-view--empty">Click on a region in the map to see consumed data</div>
  }

  if (isLoading || !data) {
    return <div className="realtime-data-view--loading">
        <div className="realtime-data-view-loading--text">Loading...</div>
        <LinearProgress />
        {appState.dataResolution === '5m' && <div className="realtime-data-view-loading--help">Searching through over a billion data points –– this might take a second.</div>}
      </div>
  }

  const isImperialUnits = appState.units === Units.Imperial

  if (dataIsEmpty(data)) {
    return <NoDataWarning />;
  }

  return (
    <Box sx={dataPanelStyle} className="data-view-container">
      <Box mt={2} className="realtime-tour-emissions-chart">
        <label className="realtime-chart--title">Total Consumed Emissions & Carbon Intensity
          <HelpPopup popupContent={
            <>
              Times are shown in Central Time.
              The red line shows the rate of emissions for grid consumption based on the fuel mix.
              The blue area shows the total emissions based on the fuels used for generation.
              The two of them are correlated directly, and the blue area also factors in the total generation.
              The red line describes the mix of fuels and the blue area describes the emissions from that mix.
            </>}
          />
        </label>
        <HighchartsReact highcharts={Highcharts} options={makeEmissionsChartOptions(data, isImperialUnits)} />
      </Box>
      <Box sx={{width: "92%"}} mt={2} className="realtime-tour-fuelmix-chart">
        <label className="realtime-chart--title">Consumed Fuel Mix</label>
        <HighchartsReact highcharts={Highcharts} options={makeFuelMixChartOptions(data)} />
      </Box>
    </Box>
  );
}


export default ConsumedDataView;
