import {
  CompactEmissionEvent,
  EmissionEventMeta,
  PlantMetadata
} from 'constants/interfaces';

import { EmissionAdjustment } from 'constants/enums';
import { api } from './base';


export interface IGetPlantMetadataParams {
  entityType: string
  entityCodes: string[]
  fuelCategories?: string[]
  fuelMapping?: string
  start: string
  end: string
}


export interface IGetPlantMetadataResponse {
  data: PlantMetadata[]
}


export interface IGetEmissionEventsByFuelCategoryParams {
  entityType: string
  entityCodes: string[]
  fuelCategories?: string[]
  fuelMapping?: string
  start: string
  end: string
  emissionFramework: string
  emissionAdjustment?: string
  outputResolution: string
  filterMonths?: string
}


export interface IGetEmissionEventsByFuelCategoryResponse {
  data: {[fuelCategory: string]: CompactEmissionEvent[]}
  meta: EmissionEventMeta
}

interface IGetRealtimeData {
  region: string
  start: string
  end: string
  source: 'ISO' | 'EIA' | 'ISO-H'
  resolution: '1h' | '5m'
  factorAdjustment: EmissionAdjustment
}

interface IGetMarginalData {
  region: string
  start: string
  end: string
  source: 'ISO' | 'EIA' | 'ISO-H'
  resolution: '1h' | '5m'
  factorAdjustment: EmissionAdjustment
}

export type AggType = 'Footprint' | 'Subregion' | 'LRZ' | 'LBA' | 'State' | 'County'

interface IGetConsumedData {
  start: string
  end: string
  agg_type: AggType
  agg_ids: string
}

interface IGeneratedCarbonIntensity {
  startDate: string
  data: {
    generatedRateKgPerMwh: number
    generatedRateLbPerMwh: number
  }
}


interface IConsumedCarbonIntensity {
  startDate: string
  data: {
    consumedRateKgPerMwh: number
    consumedRateLbPerMwh: number
  }
}

export interface IGetRealtimeDataResponse {
  emissionFactors: Record<string, number>
  carbonIntensityForecast: any[]
  fuelMix: {
    startDate: string
    data: {
      biogasMw: number;
      biomassMw: number;
      coalMw: number;
      dualFuelMw: number;
      fossilFuelMw: number;
      geothermalMw: number;
      landfillGasMw: number;
      hydroMw: number;
      multiFuelMw: number;
      naturalGasMw: number;
      naturalGasCcMw: number;
      nuclearMw: number;
      oilMw: number;
      otherMw: number;
      refuseMw: number;
      renewablesMw: number;
      solarMw: number;
      storageMw: number;
      windMw: number;
      woodMw: number;
    }
  }[]
  carbonIntensity: {
    consumed: IConsumedCarbonIntensity[]
    generated: IGeneratedCarbonIntensity[]
  }
}

export interface IGetMarginalDataResponse {
  marginalCarbonIntensity: {
    startDate: string,
    data: {
      marginalRateKgPerMwh: number,
      marginalRateLbPerMwh: number,
    }
  }[],
  marginalFuelMix: {
    startDate: string,
    data: {
      hydro: number,
      naturalGas: number,
      coal: number,
      solar: number,
      wind: number,
      other: number,
      storage: number,
    }
  }[]
}

export interface IConsumedDatum {
  timestamp: string,
  aggType: string,
  aggId: string,
  consumedMwh: number,
  generatedMwh: number,
  co2EConsumedLbs: number,
  nonCfeConsumedMwh: number,
  biomassConsumedMwh: number,
  coalConsumedMwh: number,
  hydroConsumedMwh: number,
  naturalGasConsumedMwh: number,
  nuclearConsumedMwh: number,
  otherConsumedMwh: number,
  petroleumConsumedMwh: number,
  solarConsumedMwh: number,
  unknownConsumedMwh: number,
  wasteConsumedMwh: number,
  windConsumedMwh: number,
  storageConsumedMwh: number,
  co2EGeneratedLbs: number,
  nonCfeGeneratedMwh: number,
  biomassGeneratedMwh: number,
  coalGeneratedMwh: number,
  hydroGeneratedMwh: number,
  naturalGasGeneratedMwh: number,
  nuclearGeneratedMwh: number,
  otherGeneratedMwh: number,
  petroleumGeneratedMwh: number,
  solarGeneratedMwh: number,
  unknownGeneratedMwh: number,
  wasteGeneratedMwh: number,
  windGeneratedMwh: number,
  storageGeneratedMwh: number
}

export interface IGetConsumedDataResponse {
  data: IConsumedDatum[]
}



export const dataApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlantMetadata: build.query<IGetPlantMetadataResponse, IGetPlantMetadataParams>({
      query: (params: IGetPlantMetadataParams) => {
        return {
          url: '/v4/plant_metadata',
          method: 'GET',
          params: params
        }
      },
      providesTags: ['PlantMetadata']
    }),
    getEmissionEventsByFuelCategory: build.query<IGetEmissionEventsByFuelCategoryResponse, IGetEmissionEventsByFuelCategoryParams>({
      query: (params: IGetEmissionEventsByFuelCategoryParams) => {
        return {
          url: '/v4/emission_events/by_fuel_category',
          method: 'GET',
          params: params
        }
      },
      providesTags: ['EmissionEvents']
    }),
    getRealtimeData: build.query<IGetRealtimeDataResponse, IGetRealtimeData>({
      query: (params) => {
        const search = new URLSearchParams({
          start: params.start,
          end: params.end,
          region: params.region,
          source: params.source,
          resolution: params.resolution,
          factor_adjustment: params.factorAdjustment,
        });
        return {
          url: `/v1/real-time/realtime-data?${search}`,
        }
      },
    }),
    getMarginalData: build.query<IGetMarginalDataResponse, IGetMarginalData>({
      query: (params) => {
        const search = new URLSearchParams({
          start: params.start,
          end: params.end,
          region: params.region,
          source: params.source,
          resolution: params.resolution,
          factor_adjustment: params.factorAdjustment,
        });
        return {
          url: `/v1/real-time/marginal?${search}`,
        }
      },
    }),
    getConsumedData: build.query<IGetConsumedDataResponse, IGetConsumedData>({
      query: (params) => {
        const search = new URLSearchParams({
          start: params.start,
          end: params.end,
          agg_type: params.agg_type,
          agg_ids: params.agg_ids
        });
        return {
          url: `/v1/carbonflube/hourly-aggregated-emissions?${search}`
        }
      }
    })
  })
});


export const {
  useGetPlantMetadataQuery,
  useGetEmissionEventsByFuelCategoryQuery,
  useGetRealtimeDataQuery,
  useGetMarginalDataQuery,
  useGetConsumedDataQuery
} = dataApi;
